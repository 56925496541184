/*
 * For ES6+ features:
 * Either import '@babel/polyfill' here, or
 * use https://cdn.polyfill.io/v2/polyfill.min.js (default for this project)
 */

import "./dgc.css";
import { EventBus, EventTypes } from "./consts/const-event-types";

/*
To add new components, import them here and add them to the 'components'-array below.
Add an appropriate selectorName for use in DOM (fx: <div data-component="header"></div>):
*/
import App from "./components/app/app";
import Header from "./components/header/header";
import TabModule from "./components/tab-module/tab-module";
import HelpPopUp from "./components/help-popup/help-popup";
import AnchorMenu from "./components/anchor-component/anchor-menu";
import AlertComponent from "./components/alert-component/alert-component";
import ImageContainer from "./components/image-container/image-container";
import MasonryComponent from "./components/masonry/masonry";
import NewsListComponent from "./components/newslist-component/newslist-component";
import SupportSearch from "./components/support-search/support-search";
import SupportList from "./components/support-list/support-list";
import ZipPopUp from "./components/zipPopUp/zipPopUp";
import NewsComponent from "./components/news-component/news-component";
import AccordionComponent from "./components/accordion-component/accordion-component";
import ContactSelection from "./components/contact-selection/contact-selection";
import VimeoComponent from "./components/vimeo-component/vimeo-component";
import FpHeader from "./components/fpheader/fpheader";
import ShareComponent from "./components/share-component/share-component";
import RichTextComponent from "./components/richtext-component/richtext-component";
import ContactForm from "./components/contact-selection/contact-form";
import AnimateSection from "./components/animate-section/animate-section";
import SearchComponent from "./components/search-component/search-component";
import GlobalSearch from "./components/search-component/global-search";
import SupportPage from "./components/support-layout/support-layout";
import RedirectPopUp from "./components/redirectZipPopup/redirectPopup";
import ContactCall from "./components/contact-selection/contact-call";
import ConnectionComponent from "./components/connection-component/connection-component";
import BusinessConnectionComponent from "./components/connection-component/businessconnection-component";
import Glider from "./components/Glide/glide";
import EditZip from "./components/edit-zip/edit-zip";
import SupportMenu from "./components/support-menu/support-menu"
import ContactSelect from "./components/contact-page-top/contact-select"
import ContactSelectionEmail from "./components/contact-selection/contact-selection-email";

const components = [
  { Class: App, selectorName: "app" },
  { Class: Header, selectorName: "header" },
  { Class: TabModule, selectorName: "tab-module" },
  { Class: HelpPopUp, selectorName: "help-popup" },
  { Class: AnchorMenu, selectorName: "anchor-menu" },
  { Class: AlertComponent, selectorName: "alert-component" },
  { Class: ImageContainer, selectorName: "image-container" },
  { Class: MasonryComponent, selectorName: "masonry" },
  { Class: NewsListComponent, selectorName: "newslist-component" },
  { Class: SupportSearch, selectorName: "support-search" },
  { Class: SupportList, selectorName: "support-list" },
  { Class: ZipPopUp, selectorName: "zipPopUp" },
  { Class: NewsComponent, selectorName: "news-component" },
  { Class: AccordionComponent, selectorName: "accordion-component" },
  { Class: ContactSelection, selectorName: "contact-selection" },
  { Class: VimeoComponent, selectorName: "vimeo-component" },
  { Class: FpHeader, selectorName: "fp-header" },
  { Class: ShareComponent, selectorName: "share-component" },
  { Class: RichTextComponent, selectorName: "richtext-component" },
  { Class: ContactForm, selectorName: "contact-form" },
  { Class: AnimateSection, selectorName: "animate-section" },
  { Class: SearchComponent, selectorName: "search-component" },
  { Class: GlobalSearch, selectorName: "global-search" },
  { Class: SupportPage, selectorName: "support-page" },
  { Class: RedirectPopUp, selectorName: "redirect-PopUp" },
  { Class: ContactCall, selectorName: "contact-call" },
  { Class: ConnectionComponent, selectorName: "connection-component" },
  { Class: ContactSelectionEmail, selectorName: "contact-selection-email"},
  {
    Class: BusinessConnectionComponent,
    selectorName: "businessconnection-component"
  },
  { Class: Glider, selectorName: "glide" },
  {Class: EditZip, selectorName: "edit-zip"},
  {Class: SupportMenu, selectorName: "support-menu"},
  {Class: ContactSelect, selectorName: "contact-select"},
];

/*
 * ComponentInstantiator traverses the DOM-tree and looks for [data-component='] attributes.
 */
class ComponentInstantiator {
  constructor() {
    this.createComponents();
    this.dispatchReady();
    return this;
  }

  createComponents() {
    this.componentsInstantiated = {};

    components.map(component => {
      const nodes = [];
      // select all DOM-nodes with the data-component attribute:
      const nodeItems = [
        ...document.querySelectorAll(
          "[data-component='" + component.selectorName + "']"
        )
      ];

      nodeItems.map(nodeItem => {
        // instantiate component with nodeItem as param:
        nodes.push(new component.Class(nodeItem));
      });

      // add to componentsList object:
      if (nodes.length)
        this.componentsInstantiated[component.selectorName] = nodes;
    });
  }

  dispatchReady() {
    const event = new CustomEvent(EventTypes.ALL_COMPONENTS_READY);
    EventBus.dispatchEvent(event);
  }

  getComponents(selectorName) {
    return selectorName
      ? this.componentsInstantiated[selectorName]
      : this.componentsInstantiated;
  }
}

/* Make your App accessible via window.App */
window.App = new ComponentInstantiator();

/* Access components list */
//console.log("components on this page", window.App.getComponents());
