export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches;
};
export const isIpad = () => {
  return window.matchMedia("(min-width: 768px)").matches;
};

export const isDesktop = () => {
  return window.matchMedia("(min-width: 1024px)").matches;
};

export const getViewPortWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

export const getViewPortHeight = () => {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
};

export const getRandomId = () => {
  return `id_${Math.random()
    .toString()
    .slice(2)}`;
};

export const detectOverlap = (elOne, elTwo) => {
  var d1_offset = elOne.getBoundingClientRect();
  var d1_height = elOne.offsetHeight;
  var d1_width = elOne.offsetWidth;
  var d1_distance_from_top = d1_offset.top + d1_height;
  var d1_distance_from_left = d1_offset.left + d1_width;

  // Div 2 data
  var d2_offset = elTwo.getBoundingClientRect();
  var d2_height = elTwo.offsetHeight;
  var d2_width = elTwo.offsetWidth;
  var d2_distance_from_top = d2_offset.top + d2_height;
  var d2_distance_from_left = d2_offset.left + d2_width;

  var not_colliding =
    d1_distance_from_top < d2_offset.top ||
    d1_offset.top > d2_distance_from_top ||
    d1_distance_from_left < d2_offset.left ||
    d1_offset.left > d2_distance_from_left;

  // Return whether it IS colliding
  return !not_colliding;
};
