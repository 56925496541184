import { EventBus, EventTypes } from "../../consts/const-event-types";
import { isDesktop } from "../../services/service-utils";

export default class TabModule {
  constructor(element) {
    this.el = element;
    this.tabButtons = this.el.querySelectorAll(".tab-button");
    this.tabContainer = this.el.querySelector(".tab-module-container");
    this.tabContent = this.el.querySelectorAll(".tab-container");
    this.toggleTab = this.toggleTab.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.init();
  }

  init() {
    let resizeTimer = {};

    if (isDesktop()) {
      this.calculateHeight();
    }
    window.addEventListener("resize", event => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(
        function() {
          if (isDesktop()) {
            this.calculateHeight();
          } else {
            this.tabContainer.style.height = "auto";
          }
        }.bind(this),
        250
      );
    });

    this.tabButtons.forEach(element => {
      element.addEventListener("click", event => {
        this.toggleTab(element.dataset.tab);
      });
    });
  }

  calculateHeight() {
    let tabHeighe = 0;
    this.tabContent.forEach(element => {
      var positionInfo = element.getBoundingClientRect();
      var height = element.scrollHeight;

      if (element.offsetHeight > tabHeighe) tabHeighe = element.offsetHeight;
    });

    this.tabContainer.style.minHeight = tabHeighe + "px";
  }

  toggleTab(tabNumber) {
    this.tabButtons.forEach(element => {
      if (element.dataset.tab === tabNumber) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });

    this.tabContent.forEach(element => {
      if (element.dataset.tabcontent === tabNumber) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }
}
