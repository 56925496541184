import { isMobile } from "../../services/service-utils";

export default class AnchorMenu {
  constructor(element) {
    this.el = element;
    this.list = this.el.querySelector("ul");
    this.anchorLinks = this.el.querySelectorAll("a");
    this.anchorSections = document.querySelectorAll(".rowanchor");
    this.footer = document.querySelector("footer");
    this.scrollPoints = this.scrollPoints.bind(this);
    this.durations = 0;
    this.pinmenu = {};
    this.pinMenu = this.pinMenu.bind(this);
    this.footerObserver = this.footerObserver.bind(this);
    this.checkForMargin = this.checkForMargin.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.anchorWidth = 10;
    this.isOpening = false;
    this.isClosing = false;

    this.init();
  }

  init() {
    this.anchorLinks.forEach(anchorlink => {
      anchorlink.addEventListener("click", event => {
        event.preventDefault();
        this.scrollToAnchor(event, anchorlink);
      });
      var linkContent = anchorlink.querySelector("span");
      this.anchorWidth =
        linkContent.offsetWidth + 10 > this.anchorWidth
          ? linkContent.offsetWidth + 10
          : this.anchorWidth;
    });

    this.el.addEventListener(
      "mouseenter",
      event => {
        this.openMenu(this.anchorWidth, "open");
      },
      false
    );

    this.el.addEventListener(
      "mouseleave",
      event => {
        this.closeMenu(10, "close");
      },
      false
    );

    if (this.anchorSections) {
      this.anchorSections.forEach(section => {
        this.checkForMargin(section);
      });
    }

    this.scrollPoints();
    this.pinMenu();
    this.footerObserver();
  }

  openMenu(width, direction) {
    var stagger = 0.5;
    var timerid;
    if (timerid) {
      clearTimeout(timerid);
    }
    if (!this.isClosing) {
      this.isOpening = true;
      this.list.style.width = width + "px";
      this.anchorLinks.forEach((anchorLink, index) => {
        timerid = setTimeout(() => {
          anchorLink.style.width = width + "px";
          if (index + 1 === this.anchorLinks.length) {
            this.isOpening = false;
            clearTimeout(timerid);
          }
        }, 100 * stagger++);
      });
    }
  }

  closeMenu(width, direction) {
    var stagger = 0.5;
    var timerid;
    var arrayNodes = Array.from(this.anchorLinks);
    if (timerid) {
      clearTimeout(timerid);
    }
    if (!this.isOpening) {
      this.isClosing = true;
      arrayNodes
        .slice()
        .reverse()
        .forEach((anchorLink, index) => {
          timerid = setTimeout(() => {
            anchorLink.style.width = width + "px";
            if (index + 1 === this.anchorLinks.length) {
              this.isClosing = false;
              clearTimeout(timerid);
            }
          }, 100 * stagger++);
        });
      this.list.style.width = "auto";
    } else {
      setTimeout(() => {
        this.closeMenu(width, direction);
      }, 300);
    }
  }

  footerObserver() {
    var main = document.querySelector("main");
    var minusHeight = window.innerHeight - 150;

    var intersectionObserver = new IntersectionObserver(
      function(entries) {
        if (entries[0].intersectionRatio > 0) {
          this.pinmenu.duration(main.offsetHeight - minusHeight);
          this.pinmenu.refresh();
        } else {
          this.pinmenu.duration(0);
          this.pinmenu.refresh();
        }
      }.bind(this)
    );
    intersectionObserver.observe(this.footer);

    window.addEventListener(
      "orientationchange",
      function() {
        setTimeout(
          function() {
            this.pinmenu.removePin(true);
            this.pinMenu();
          }.bind(this),
          10
        );
      }.bind(this),
      false
    );
  }

  scrollPoints() {
    var ctrl = new ScrollMagic.Controller();

    this.anchorSections.forEach(section => {
      var height = section.offsetHeight;
      var anchorLink = document.querySelector(
        "[href='#" + section.dataset.anchor + "']"
      );
      new ScrollMagic.Scene({
        triggerElement: section,
        triggerHook: 0.5,
        duration: height
      })
        .on("enter", function() {
          anchorLink.classList.add("active");
        })
        .on("leave", function() {
          anchorLink.classList.remove("active");
        })
        .addTo(ctrl);
    });
  }

  pinMenu() {
    var main = document.querySelector("main");
    var minusHeight = window.innerHeight + 50;
    var ctrl1 = new ScrollMagic.Controller();

    this.pinmenu = new ScrollMagic.Scene({
      triggerElement: "main",
      triggerHook: 0
    })
      .setPin(".anchor-menu")
      .addTo(ctrl1);
  }

  getOffsetTop(element) {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  }

  filterPath(string) {
    return string
      .replace(/^\//, "")
      .replace(/(index|default).[a-zA-Z]{3,4}/, "")
      .replace(/\//, "");
  }

  scrollToAnchor(event, anchorElement) {
    if (this.animatedSections) {
      this.animatedSections.forEach(element => {
        element.classList.add("animation-done");
      });
    }

    let anchorLink = anchorElement.dataset.anchorlink;
    let anchor = document.querySelector("[data-anchor='" + anchorLink + "']");

    var scrollToPosition = this.getOffsetTop(anchor) - 145;

    window.scrollTo(0.1, scrollToPosition);
  }

  checkForMargin(section) {
    var elements = section.children;
    var firstSection = elements[1];
    var lastElement = section.lastElementChild;
    if (lastElement && lastElement.classList.contains("section-padding")) {
      section.classList.add("add-margin-bottom");
    }
    if (firstSection && !firstSection.classList.contains("section-padding")) {
      section.classList.add("add-margin-top");
    }
  }
}
