//import users from "../../json/mockdata-users.json";
import { EventBus, EventTypes } from "../../consts/const-event-types";

/*
Use App.js for global functionality
*/

export default class App {
  constructor(element) {
    // save a reference of your component-element so you can access it from your methods:
    this.el = element;

    // bind your methods to 'this' when calling them from event-listeners:
    this.onAppReady = this.onAppReady.bind(this);

    this.init();
  }

  init() {
    // Use init-method to store DOM-references and setup initial event-listeners:
    if (this.el)
      this.someElement = this.el.querySelector("[data-example-js-selector]");

    // You could listen for all components to be instantiated to make sure events between components are captured:
    EventBus.addEventListener(EventTypes.ALL_COMPONENTS_READY, this.onAppReady);
  }

  onAppReady(event) {
    //console.log(event.type)

    // create your own event-types for component-to-component communication (fx. this event will be picked up by Header.js component):
    const eventData = { detail: new Date().toJSON() };
    const ev = new CustomEvent(EventTypes.MY_CUSTOM_EVENT, eventData);
    EventBus.dispatchEvent(ev);

    // this.exampleES6Features()
    // this.exampleRenderSomeContent()
  }

  exampleES6Features() {
    console.log("----------------------------");
    console.log("Here are some random ES6+ features...");

    const someString = "something is rotten in Denmark";
    const hasDenmark = someString.includes("Denmark");
    console.log("hasDenmark", hasDenmark);

    const someNodeList = document.querySelectorAll("p");
    console.log("someNodeList", someNodeList);

    // using ES6 syntax to convert the NodeList to an Array (gives access to useful array-methods):
    const convertedToArray = [...someNodeList];
    console.log("nodeList converted to array", convertedToArray);

    // import json-data:
    console.log("imported JSON-data", users);

    // using forEach for looping over array-items:
    users.forEach((user, index) => {
      // using Spread operators to extract properties:
      const { name, gender } = user;
      console.log("forEach", index, name, gender);
    });

    // using a for..of loop:
    for (const user of users) {
      console.log("for of loop", user.stars);
    }

    // using map to loop and return a new array:
    const userNames = users.map(user => user.name);
    console.log("userNames", userNames);

    // chain .filter and .map to create new array:
    const femaleUserNamesArray = users
      .filter(user => user.gender === "female")
      .map(user => user.name);
    console.log("femaleUserNamesArray", femaleUserNamesArray);

    // using reduce to fx. sum up values in array:
    const sumUserStars = this.sumUserStars(users);
    console.log("sumUserStars", sumUserStars);
    console.log("----------------------------");
  }

  sumUserStars(users) {
    return users.reduce((sum, current) => {
      return sum + current.stars;
    }, 0);
  }

  exampleRenderSomeContent() {
    const htmlTemplate = `
    <div>
      <p class="hello">
        <span>HTML-template created from index.js</span>
        <br />
        <span>${users[0].name} injected</span>
      </p>
    </div>
    `;

    if (this.someElement) {
      this.someElement.innerHTML = htmlTemplate;
      console.log("someElement", this.someElement);
    }
  }
}
