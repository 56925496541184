import { isDesktop } from "../../services/service-utils";

export default class AlertComponent {
  constructor(element) {
    this.el = element;
    this.newsInformation = this.el.querySelector(".news-information");
    this.newsGrid = this.el.querySelector(".news-grid");
    this.setNewsHeight = this.setNewsHeight.bind(this);
    this.init();
  }

  init() {
    if (this.newsInformation) {
      var loadHeight = this.newsInformation.offsetHeight;
      this.setNewsHeight(loadHeight);
      var ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (loadHeight != this.newsInformation.offsetHeight)
            this.setNewsHeight(this.newsInformation.offsetHeight);
          loadHeight = this.newsInformation.offsetHeight;
        }
      });

      ro.observe(this.newsInformation);
    }
  }

  setNewsHeight(height) {
    if (isDesktop()) {
      this.el.style.minHeight = height + "px";
    } else {
      this.el.style.minHeight = "";
    }
  }
}
