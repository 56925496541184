import {
  getCookieAcceptance,
  setCookie,
  getCookieValue,
  removeCookie
} from "../../services/service-cookies";

export default class ContactCall {
  constructor(element) {

    this.el = element;
    this.contactTab = document.querySelector(".contact-tab");
    this.zipInput = this.el.querySelector("#call-zip");
    this.zipButton = this.el.querySelector(".cta");
    this.zipButtonText = this.zipButton.dataset.editziptext;
    this.validateZip = this.validateZip.bind(this);
    this.fallback = this.el.querySelector(".fallback-contact");
    this.timeTable = this.el.querySelector(".area-contact");
    this.buildTable = this.buildTable.bind(this);
    this.site = this.el.dataset.site;
    
    this.init();
  }

  init() {
    const postnummerRegEx = /^[0-9]{4}$/;
    let currentZip = getCookieValue("evidaZipCode");

    if (currentZip) {
      this.zipInput.value = currentZip;
      this.zipButton.disabled = false;
      this.zipButton.innerHTML = this.zipButtonText;
      this.validateZip(currentZip);
    }

    this.zipInput.addEventListener("input", event => {
      if (this.zipInput.value.length == 4) {
        this.zipButton.disabled = false;
      } else {
        this.zipButton.disabled = true;
      }
    });

    this.zipInput.addEventListener("keyup", event => {
      if (event.keyCode === 13 && this.zipInput.value.length == 4) {
        if (postnummerRegEx.test(this.zipInput.value)) {
          let myZip = this.zipInput.value;
          this.validateZip(myZip);
        }
      }
    });

    this.zipButton.addEventListener("click", event => {
      let myZip = this.zipInput.value;
      this.validateZip(myZip);
    });
  }

  validateZip(zipcode) {
    let data = {
      postalCode: zipcode
    };

    fetch(`/umbraco/api/areas/getareaforpostalcode?site=` + this.site, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.id > 0) {
          this.fallback.classList.remove("active");
          setCookie("evidaZipCode", zipcode, 367);
          setCookie("evidaHelpCookie", true);
          this.buildTable(json);
        } else {
          removeCookie("evidaZipCode");
          removeCookie("evidaHelpCookie");
          this.timeTable.classList.remove("active");
          this.fallback.classList.add("active");
        }
      })
      .catch(e => {});
  }

  buildTable(json) {
    const htmlTemplate = `
        <div class="row headline">
          <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-2 col-lg-4 col-lg-offset-4">
            ${json.contactSectionOpeningHours}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-10 col-xs-offset-1 col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-2 col-lg-2 col-lg-offset-4 key-map">
              <div class="risk low-risk">
                  Mindst risiko
              </div>
          </div>
          <div class="col-xs-10 col-xs-offset-1 col-sm-3 col-sm-offset-0 col-md-2 col-md-offset-0 col-lg-2 col-lg-offset-0 key-map">
              <div class="risk medium-risk">
                  Risiko for ventetid
              </div>
          </div>
          <div class="col-xs-10 col-xs-offset-1 col-sm-3 col-sm-offset-0 col-md-3 col-md-offset-0 col-lg-2 col-lg-offset-0 key-map">
              <div class="risk high-risk">
                  Høj risiko for ventetid
              </div>
          </div>
          <div class="col-xs-10 col-xs-offset-1 col-sm-2 col-sm-offset-0 col-md-3 col-md-offset-0 col-lg-2 col-lg-offset-0 key-map">
          <div class="risk closed">
             Lukket
          </div>
      </div>
        </div>
        <div class="row">
                <div class="col-xs-10 col-xs-offset-1 col-md-7 col-md-offset-2 col-lg-6 col-lg-offset-4">
                    <div class="time-table">
                        <div class="table-row">
                            <div class="time-slots">&nbsp;</div>
                            <div class="time-key">M</div>
                            <div class="time-key">T</div>
                            <div class="time-key">O</div>
                            <div class="time-key">T</div>
                            <div class="time-key">F</div>
                        </div>
      `;

    const timeTableList = json.contactSectionCallTimes
      .map(item => {
        return `
        <div class="table-row">
      <div class="time-slots">${item.title}</div>
      <div class="time-key"><span style="background-color:#${item.monday}"></span></div>
      <div class="time-key"><span style="background-color:#${item.tuesday}"></span></div>
      <div class="time-key"><span style="background-color:#${item.wednesday}"></span></div>
      <div class="time-key"><span style="background-color:#${item.thursday}"></span></div>
      <div class="time-key"><span style="background-color:#${item.friday}"></span></div>
      </div>
      `;
      })
      .join("");

    this.timeTable.innerHTML = "";
    this.timeTable.classList.add("active");
    this.timeTable.innerHTML =
      htmlTemplate + timeTableList + "</div></div></div>";
  }
}
