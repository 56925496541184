import Masonry from "masonry-layout";
export default class MasonryComponent {
  constructor(element) {
    this.el = element;
    this.grid = this.el.querySelector(".grid");
    this.gridElements = this.el.querySelectorAll(".grid-item");
    this.gridSizer = this.el.querySelector(".grid-sizer");
    this.MasonryInit = this.MasonryInit.bind(this);

    this.init();
  }

  init() {
    if (this.grid) {
      this.MasonryInit();
    }
  }

  MasonryInit() {
    var elem = this.grid;
    var msnry = new Masonry(elem, {
      initLayout: false,
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      percentPosition: true,
      horizontalOrder: true,
      stagger: 30
    });
  }
}
