export default class SupportList {
  constructor(element) {
    this.el = element;
    this.lists = this.el.querySelectorAll(".support-subject-list");
    this.openText = this.el.dataset.open;
    this.closeText = this.el.dataset.close;
    this.listToggle = this.listToggle.bind(this);
    this.init();
  }

  init() {
    this.lists.forEach(element => {
      const listButton = element.querySelector(".open-list");
      if (listButton) {
        listButton.addEventListener("click", event => {
          this.listToggle(element, listButton);
        });
      }
    });
  }

  listToggle(element, button) {
    let innerText = this.openText;
    let listContainer = element.querySelector(".support-subject-list-outer");
    let list = listContainer.querySelector(".support-subject-list-inner");
    let listHeight = list.offsetHeight;

    if (button.classList.contains("is-open")) {
      button.classList.remove("is-open");
      listContainer.style.height = "";
    } else {
      button.classList.add("is-open");
      innerText = this.closeText;
      listContainer.style.height = listHeight + "px";
    }
    button.innerHTML = innerText;
  }
}
