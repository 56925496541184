export default class ConnectionComponent {
  constructor(element) {
    this.el = element;
    this.gridid = this.el.dataset.gridid;
    this.nodeId = this.el.dataset.nodeid;
    this.title = this.el.dataset.formtitle;
    this.endStep = this.el.querySelector(".end-step");
    this.endStepDescription = this.el.querySelector(".end-step-description");
    this.slides = this.el.querySelectorAll("fieldset");
    this.initialSlide = this.el.querySelector(".center");
    this.initialSlideNr = this.initialSlide.dataset.slide;
    this.slideCounter = this.slides.length;
    this.initialStep = this.el.querySelector(
      "[data-step='" + this.initialSlideNr + "']"
    );
    this.prev = this.el.querySelector("[data-prev]");
    this.next = this.el.querySelector("[data-next]");
    this.steps = this.el.querySelectorAll(".connection-steps ul li");
    this.slideContainer = this.el.querySelector(".fieldset-container");
    this.navigation = this.el.querySelector(".connection-navigation");
    this.editLink = this.el.querySelectorAll(".edit");

    this.address = this.el.querySelector("#address");

    this.zip = this.el.querySelector("#zip");
    this.city = this.el.querySelector("#city");
    this.ownerName = this.el.querySelector("#ownername");
    this.ownerPhone = this.el.querySelector("#ownerphone");
    this.ownerEmail = this.el.querySelector("#owneremail");
    this.ownerAddress = this.el.querySelector("#owneraddress");
    this.ownerZip = this.el.querySelector("#ownerzip");
    this.ownerCity = this.el.querySelector("#ownercity");
    this.otherName = this.el.querySelector("#othername");
    this.otherPhone = this.el.querySelector("#otherphone");
    this.otherEmail = this.el.querySelector("#otheremail");
    this.forbrug = this.el.querySelector("#forbrug");
    this.forventedForbrug = this.el.querySelector("#forventedforbrug");
    this.arial = this.el.querySelector("#arial");
    this.dato = this.el.querySelector("#datepicker");
    this.postFormButton = this.el.querySelector(".post-form");
    this.dataRow = this.el.querySelectorAll(".data-row");

    this.addressContainer = this.el.querySelector("[data-address]");
    this.ownerContainer = this.el.querySelector("[data-owner]");
    this.otherOwnerContainer = this.el.querySelector("[data-otherowner]");
    this.usageContainer = this.el.querySelector("[data-usage]");
    this.expectedContainer = this.el.querySelector("[data-expected]");
    this.areaContainer = this.el.querySelector("[data-area]");
    this.dateContainer = this.el.querySelector("[data-date]");

    this.moveSlide = this.moveSlide.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.confirmForm = this.confirmForm.bind(this);
    this.moveToSlide = this.moveToSlide.bind(this);
    this.postform = this.postform.bind(this);
    this.init();
  }

  init() {
    let minHeight = this.initialSlide.offsetHeight;
    this.slideContainer.style.height = minHeight + 40 + "px";
    this.initialStep.classList.add("active");

    this.next.addEventListener("click", event => {
      let thisActiveSlide = this.el.querySelector(".center");
      this.validateForm(thisActiveSlide);
    });

    this.prev.addEventListener("click", event => {
      this.moveSlide("backwards");
    });

    this.editLink.forEach(link => {
      link.addEventListener("click", event => {
        var slideNumber = Number(link.dataset.edit);
        this.moveToSlide(slideNumber);
      });
    });

    this.postFormButton.addEventListener("click", event => {
      this.postform();
    });
  }

  moveToSlide(slideNr) {
    slideNr = Number(slideNr);
    var slideToSlide = this.el.querySelector("[data-slide='" + slideNr + "']");

    this.slides.forEach(slide => {
      if (slide.dataset.slide > slideNr) {
        slide.classList.remove("left");
        slide.classList.remove("center");
        slide.classList.add("right");
      }
    });
    this.slideContainer.style.height = slideToSlide.offsetHeight + 40 + "px";
    this.navigation.style.display = "";
    slideToSlide.classList.add("center");
    slideToSlide.classList.remove("left");

    this.steps.forEach(step => {
      var stepNr = Number(step.dataset.step);
      if (stepNr == slideNr) {
        step.classList.add("active");
      } else {
        step.classList.remove("active");
      }
    });
    slideToSlide.scrollIntoView({ block: "center" });
  }

  validateForm(slide) {
    var requiredFields = slide.querySelectorAll("[required]");
    var canPostForm = true;
    requiredFields.forEach(field => {
      var fieldID = field.id;
      var errorText = ".error-" + fieldID;
      var errorbox = slide.querySelector(errorText);
      if (!field.validity.valid) {
        canPostForm = false;
        errorbox.classList.add("active");

        this.slideContainer.style.height = slide.offsetHeight + 40 + "px";
      } else {
        errorbox.classList.remove("active");
      }
    });

    for (let i = 0; i < requiredFields.length; i++) {
      if (!requiredFields[i].validity.valid) {
        break;
      }
    }

    if (canPostForm) {
      this.moveSlide("forward");
    }
  }

  moveSlide(direction) {
    let thisSlide = this.el.querySelector(".center");
    let required = thisSlide.querySelectorAll("[required]");
    let thisSlideNr = Number(thisSlide.dataset.slide);
    let nextSlideNr = thisSlideNr + 1;
    let prevSlideNr = thisSlideNr - 1;
    let thisStep = this.el.querySelector("[data-step='" + thisSlideNr + "']");

    if (direction === "forward" && nextSlideNr <= this.slideCounter) {
      let nextSlide = this.el.querySelector(
        "[data-slide='" + nextSlideNr + "']"
      );
      let thisHeader = nextSlide.querySelector(".h2");
      let nextStep = this.el.querySelector("[data-step='" + nextSlideNr + "']");
      this.slideContainer.style.height = nextSlide.offsetHeight + 40 + "px";
      thisStep.classList.remove("active");
      nextStep.classList.add("active");
      thisSlide.classList.add("left");
      thisSlide.classList.remove("center");
      nextSlide.classList.add("center");
      nextSlide.classList.remove("right");
      this.prev.classList.remove("inactive");
      if (nextSlideNr === this.slideCounter) {
        this.confirmForm();
        this.slideContainer.style.height = nextSlide.offsetHeight + 40 + "px";
        this.navigation.style.display = "none";
      }
      thisHeader.scrollIntoView({ block: "center" });
    }

    if (direction === "backwards" && prevSlideNr > 0) {
      let prevSlide = this.el.querySelector(
        "[data-slide='" + prevSlideNr + "']"
      );
      let thisHeader = prevSlide.querySelector(".h2");
      let prevStep = this.el.querySelector("[data-step='" + prevSlideNr + "']");
      this.slideContainer.style.height = prevSlide.offsetHeight + 40 + "px";
      thisStep.classList.remove("active");
      prevStep.classList.add("active");
      thisSlide.classList.add("right");
      thisSlide.classList.remove("center");
      prevSlide.classList.add("center");
      prevSlide.classList.remove("left");
      this.prev.classList.remove("inactive");
      thisHeader.scrollIntoView({ block: "center" });
      this.next.innerHTML = "Næste";

      if (prevSlideNr === 1) {
        this.prev.classList.add("inactive");
      }
    }
  }

  confirmForm() {
    const addressInfo = `<p>${this.address.value}<br/>${this.zip.value} ${this.city.value}</p>`;

    const ownerInfo = `<p>${this.ownerName.value}<br/>${this.ownerEmail.value}${
      this.ownerPhone.value != "" ? `<br>${this.ownerPhone.value}` : ``
    }<br/>${this.ownerAddress.value}<br/>${this.ownerZip.value} ${
      this.ownerCity.value
    }</p>`;

    const altContactInfo = `<p>${
      this.otherName.value != "" ||
      this.otherPhone.value != "" ||
      this.otherEmail.value != ""
        ? `${this.otherName.value != "" ? `${this.otherName.value}` : ``}${
            this.otherPhone.value != "" ? `<br>${this.otherPhone.value}` : ``
          }${
            this.otherEmail.value != "" ? `<br/>${this.otherEmail.value}` : ``
          }`
        : `Nej`
    }</p>`;
    const usageInfo = `<p>${
      this.forbrug.value != "" ? this.forbrug.value : `Ikke oplyst`
    }</p>`;
    const expectedInfo = `<p>${
      this.forventedForbrug.value != ""
        ? this.forventedForbrug.value
        : `Ikke oplyst`
    }</p>`;

    const areaInfo = `<p>${
      this.arial.value != "" ? this.arial.value : `Ikke oplyst`
    }</p>`;
    const dateInfo = `<p>${
      this.dato.value != "" ? this.dato.value : `Ikke oplyst`
    }`;

    this.addressContainer.innerHTML = addressInfo;
    this.ownerContainer.innerHTML = ownerInfo;

    this.otherOwnerContainer.innerHTML = altContactInfo;
    this.usageContainer.innerHTML = usageInfo;
    this.expectedContainer.innerHTML = expectedInfo;
    this.areaContainer.innerHTML = areaInfo;
    this.dateContainer.innerHTML = dateInfo;
  }

  postform() {
    const body = {
      title: this.title,
      originatingModuleId: this.gridid,
      originatingContentId: this.nodeId,
      fields: [
        {
          title: this.address.getAttribute("aria-label"),
          value: this.address.value,
          type: "string"
        },
        {
          title: this.zip.getAttribute("aria-label"),
          value: this.zip.value,
          type: "string"
        },
        {
          title: this.city.getAttribute("aria-label"),
          value: this.city.value,
          type: "string"
        },
        {
          title: this.ownerName.getAttribute("aria-label"),
          value: this.ownerName.value,
          type: "string"
        },
        {
          title: this.ownerEmail.getAttribute("aria-label"),
          value: this.ownerEmail.value,
          type: "string"
        },
        {
          title: this.ownerPhone.getAttribute("aria-label"),
          value: this.ownerPhone.value,
          type: "string"
        },
        {
          title: this.ownerAddress.getAttribute("aria-label"),
          value: this.ownerAddress.value,
          type: "string"
        },
        {
          title: "Nuværende " + this.ownerZip.getAttribute("aria-label"),
          value: this.ownerZip.value,
          type: "string"
        },
        {
          title: "Nuværende " + this.ownerCity.getAttribute("aria-label"),
          value: this.ownerCity.value,
          type: "string"
        },
        {
          title: "Kontakt " + this.otherName.getAttribute("aria-label"),
          value: this.otherName.value,
          type: "string"
        },
        {
          title: "Kontakt " + this.otherEmail.getAttribute("aria-label"),
          value: this.otherEmail.value,
          type: "string"
        },
        {
          title: "Kontakt " + this.otherPhone.getAttribute("aria-label"),
          value: this.otherPhone.value,
          type: "string"
        },
        {
          title: this.forbrug.getAttribute("aria-label"),
          value: this.forbrug.value,
          type: "string"
        },
        {
          title: this.forventedForbrug.getAttribute("aria-label"),
          value: this.forventedForbrug.value,
          type: "string"
        },
        {
          title: this.arial.getAttribute("aria-label"),
          value: this.arial.value,
          type: "string"
        },
        {
          title: this.dato.getAttribute("aria-label"),
          value: this.dato.value,
          type: "string"
        }
      ]
    };

    fetch("/umbraco/api/connectionform/submitform", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    })
      .then(result => {
        if (!result.ok) {
          throw result;
        }
        this.editLink.forEach(edit => {
          edit.style.display = "none";
        });
        this.endStep.innerHTML = this.endStep.dataset.success;
        this.endStepDescription = this.endStepDescription.dataset.success;
        this.endStep.scrollIntoView({ block: "center" });
        this.postFormButton.style.display = "none";
      })
      .catch(error => {
        //this.errorPopUp.classList.add("show-popup");
      });
  }
}
