export default class ContactSelect {
  constructor(element) {
    this.el = element;
    this.selectButtons = this.el.querySelectorAll("[data-selection]")
    this.selectedSections = this.el.querySelectorAll("[data-selectsection]")
    this.activateSection = this.activateSection.bind(this);
    this.linkList = this.el.querySelector(".link-list");
    this.contactListContainer = this.el.querySelector(".contact-list-container")
    this.init();
  }

  init() {

    if (this.selectButtons){
      this.selectButtons.forEach(element => {
        element.addEventListener('click', e =>{
          let activeSection = this.el.querySelector(".active[data-selection]")
          let sectionName = element.dataset.selection
          activeSection.classList.remove("active")
          element.classList.add("active")
          this.activateSection(element.dataset.selection);
        })
      });
    }
  }

  activateSection(selection){
    let faqLinks = this.contactListContainer.querySelectorAll("a")
    console.log('faqLinks: ', faqLinks)
    if (selection != "faq"){
      this.linkList.classList.remove("active");
    }
    else if (faqLinks.length > 0){
      this.linkList.classList.add("active");
    }
    let selectedSection = this.el.querySelector(".active[data-sectionName]")
    if (selectedSection){
      selectedSection.classList.remove("active");
    }
    let activeSection = this.el.querySelector("[data-sectionName='"+ selection +"']")
    if (activeSection){
      activeSection.classList.add("active");
    }
    
    

  }
}