import Masonry from "masonry-layout";
export default class NewsListComponent {
  constructor(element) {
    this.el = element;
    this.siteName = this.el.dataset.site;
    this.grid = this.el.querySelector(".grid");
    this.pagingContainer = this.el.querySelector(".paging");
    this.filterButtons = this.el.querySelectorAll(".filter-category");
    this.searchField = this.el.querySelector("#searchnews");
    this.searchButton = this.el.querySelector(".cta-searchnews");
    this.resetButton = this.el.querySelector(".delete-search");
    this.resultText = this.el.querySelector(".result-text");
    this.language = this.el.dataset.language;

    this.testForFilter = this.testForFilter.bind(this);
    this.getInitialResult = this.getInitialResult.bind(this);
    this.buildResult = this.buildResult.bind(this);
    this.newsMasonry = this.newsMasonry.bind(this);
    this.usePager = this.usePager.bind(this);
    this.filterCategory = this.filterCategory.bind(this);
    this.searchNews = this.searchNews.bind(this);
    this.pagePerView = this.grid.dataset.take ? this.grid.dataset.take : 10;
    this.generatePager = this.generatePager.bind(this);

    this.init();
  }

  init() {
    this.testForFilter("Pressemeddelelse");
    this.testForFilter("Case study");
    this.getInitialResult();

    this.searchButton.addEventListener("click", event => {
      if (this.searchField.value.length > 0) {
        this.searchNews(this.searchField.value);
      } else {
        this.searchNews("");
      }
      if (this.searchField.value.length > 0) {
        this.resetButton.classList.add("active");
      } else {
        this.resetButton.classList.remove("active");
      }
    });

    this.searchField.addEventListener("keyup", event => {
      if (event.keyCode === 13) {
        this.searchNews(this.searchField.value);
        if (this.searchField.value.length > 0) {
          this.resetButton.classList.add("active");
        } else {
          this.resetButton.classList.remove("active");
        }
      }
    });

    this.resetButton.addEventListener("click", event => {
      this.searchField.value = "";
      this.searchNews("");
      this.resetButton.classList.remove("active");
    });

    this.filterButtons.forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();
        this.filterCategory(element);
      });
    });
  }

  testForFilter(filter) {
    let filterButton = this.el.querySelector(
      "[data-category='" + filter + "']"
    );

    let data = {
      searchText: "",
      category: filter,
      cropAlias: "Square-medium",
      Take: this.pagePerView,
      Site: this.siteName,
      Skip: 0
    };

    fetch(`/umbraco/api/search/news`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.total == 0) {
          filterButton.style.display = "none";
        }
      })
      .catch(e => {});
  }

  getInitialResult() {
    const params = new URLSearchParams(location.search);
    let pagingParameter =
      params.get("page") != null
        ? this.pagePerView * (Math.trunc(params.get("page")) - 1)
        : 0;
    let categoryParam =
      params.get("category") != null ? params.get("category") : "";

    let searchQuery = params.get("query") != null ? params.get("query") : "";

    if (categoryParam.length > 1) {
      let filterButton = this.el.querySelector(
        ".filter-category[data-category='" + categoryParam + "']"
      );
      if (filterButton) {
        this.el
          .querySelector(".filter-category.active")
          .classList.remove("active");
        filterButton.classList.add("active");
      }
    }

    if (searchQuery.length > 1) {
      this.searchField.value = searchQuery;
    }

    let data = {
      searchText: searchQuery,
      category: categoryParam,
      cropAlias: "Square-medium",
      Take: this.pagePerView,
      Site: this.siteName,
      Skip: pagingParameter
    };
    
    fetch(`/umbraco/api/search/news`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.buildResult(json);
        if (json.total > this.pagePerView) {
          this.generatePager(json.total);
        }
      })
      .catch(e => {});
  }

  buildResult(result) {
    const htmlTemplate = result.results
      .map(item => {
        let date = new Date(item.date);
        let formattetDate = date.toLocaleDateString("da-DK");
        let tag = item.category;

        if (this.siteName == "dgc" && tag == "Pressemeddelelse") {
          switch (this.language) {
            case "DK":
              tag = "Udgivelse"
              break;
            case "EN":
              tag = "Publication"
            default:
              break;
          }
        }

        return `
      <div class="grid-item ${item.highlight ? `highlight-item` : ``}">
        <a href="${item.url}" class="color-petroleum">
          ${
            item.imageUrl
              ? `<div class="image-container ${item.imageFilter ? `` : `no-filter`}"><img src="${item.imageUrl}" /></div>`
              : ``
          }
          
          <div class="grid-item-info">
            <span class="tag">${tag == 'Case study'? `Publikation`:`${tag}`}</span>
            <span class="time">${item.readTime} min</span>
          </div>
          <div class="grid-item-content">
            <p>${item.title}</p>
          </div>
          <div class="grid-item-date">
            ${formattetDate}
          </div>
        </a>
      </div>
      `;
      })
      .join("");

    this.newsMasonry(
      htmlTemplate,
      result.total,
      result.count,
      result.request.skip
    );
  }

  newsMasonry(template, total, count, skip) {
    var myGrid = this.grid;
    var proxyElem = document.createElement("div");
    proxyElem.innerHTML = template;
    var items = proxyElem.querySelectorAll(".grid-item");
    var pressentItems = this.grid.querySelectorAll(".grid-item");
    var msnry = new Masonry(this.grid, {
      itemSelector: ".grid-item",
      columnWidth: ".grid__col-sizer",
      percentPosition: true,
      horizontalOrder: true,
      stagger: 90,
      visibleStyle: { transform: "translateY(0)", opacity: 1 },
      hiddenStyle: { transform: "translateY(100px)", opacity: 0 }
    });

    if (pressentItems.length > 0) {
      msnry.remove(pressentItems);
    }

    items.forEach(element => {
      myGrid.appendChild(element);
    });
    msnry.appended(items);
    msnry.layout();

    const params = new URLSearchParams(location.search);
    const from = skip + 1;
    const to = skip + count;
    var restulttext =
      params.get("query") != null
        ? "<p>Din søgning på <span>" +
          params.get("query") +
          "</span> gav " +
          total +
          " resultater. Viser " +
          from +
          " - " +
          to +
          " resultater.</p>"
        : "<p>Viser " +
          from +
          " - " +
          to +
          " ud af <span>" +
          total +
          "</span> resultater</p>";

    if (total === 0) {
      if (params.get("query")) {
        restulttext =
          "<p>Din søgning på <span>" +
          params.get("query") +
          "</span> gav ingen resultater.";
      } else {
        restulttext = "<p>Din søgning gav ingen resultater.";
      }
    }

    this.resultText.innerHTML = restulttext;
    this.generatePager(total);
  }

  generatePager(total) {
    const params = new URLSearchParams(location.search);
    let pagingParameter = params.get("page");
    let pages = Math.floor(total / this.pagePerView);
    let extra = total % this.pagePerView == 0 ? 0 : 1;
    let totalPages = pages + extra;

    var pagerTemplate = "";

    for (let step = 1; step <= totalPages; step++) {
      var isActive =
        pagingParameter == step || (step == 1 && pagingParameter == null)
          ? "active"
          : "";

      pagerTemplate =
        pagerTemplate +
        '<a class="paging-item ' +
        isActive +
        '" href="#" aria-label="Side ' +
        step +
        '" data-page="' +
        step +
        '">' +
        step +
        "</a>";
    }
    var startStep =
      '<a class="paging-item paging-back" data-page="0" href="#" aria-label="Forrige ' +
      this.pagePerView +
      ' sider"><svg class="svg-small-arrow-left"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-small-arrow-left"></use></svg></a>';
    var endStep =
      '<a class="paging-item paging-forward" data-page="999" href="#" aria-label="Næste ' +
      this.pagePerView +
      ' sider"><svg class="svg-small-arrow-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-small-arrow-right"></use></svg></a>';

    this.pagingContainer.innerHTML = startStep + pagerTemplate + endStep;
    this.pageButtons = this.el.querySelectorAll(".paging-item");
    this.pageButtons.forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();

        this.usePager(element, totalPages);
      });
    });
  }

  usePager(button, totalPages) {
    const params = new URLSearchParams(location.search);
    var activePage = button.dataset.page;
    var pressentActiveButton = this.el.querySelector(".paging-item.active");
    var presentActivePage = pressentActiveButton.dataset.page;

    if (activePage != 0 && activePage != 999) {
      this.pageButtons.forEach(element => {
        var page = element.dataset.page;
        if (activePage == page) {
          element.classList.add("active");
          params.set("page", page);
          window.history.pushState({}, "", "?" + params.toString());          
          this.getInitialResult();
        } else {
          element.classList.remove("active");
        }
      });
    } else if (activePage == 0 && presentActivePage != 1) {
      pressentActiveButton.classList.remove("active");
      var newActivePage = pressentActiveButton.previousSibling.classList.add(
        "active"
      );
      var newParam = Math.trunc(presentActivePage) - 1;
      params.set("page", newParam);
      window.history.pushState({}, "", "?" + params.toString());

      this.getInitialResult();
    } else if (activePage == 999 && presentActivePage != totalPages) {
      pressentActiveButton.classList.remove("active");
      pressentActiveButton.nextSibling.classList.add("active");
      var newParam = Math.trunc(presentActivePage) + 1;
      params.set("page", newParam);
      window.history.pushState({}, "", "?" + params.toString());

      this.getInitialResult();
    } else {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  filterCategory(filter) {
    const params = new URLSearchParams(location.search);
    if (params.get("page") != null) {
      params.delete("page");
    }
    let category = filter.dataset.category;
    this.el.querySelector(".filter-category.active").classList.remove("active");

    if (category == "all") {
      params.delete("category");
      window.history.pushState({}, "", "?" + params.toString());
    } else {
      params.set("category", category);
      window.history.pushState({}, "", "?" + params.toString());
    }
    filter.classList.add("active");
    this.getInitialResult();
  }

  searchNews(query) {
    const params = new URLSearchParams(location.search);
    if (params.get("page") != null) {
      params.delete("page");
    }
    if (query.length > 0) {
      params.set("query", query);
    } else {
      params.delete("query");
    }
    window.history.pushState({}, "", "?" + params.toString());
    this.getInitialResult();
  }
}
